(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/socket-with-alert/assets/javascripts/socket-with-alert.js') >= 0) return;  svs.modules.push('/components/components/socket-with-alert/assets/javascripts/socket-with-alert.js');

svs.core.socket.on('dead', function() {
  const branding = svs.core.detect.tenant('tb') ? svs.components.dialog.branding.TUR : svs.components.dialog.branding.SPORT;
  const deadAlert = new svs.components.dialog.Confirm({
    branding: branding,
    type: svs.components.dialog.type.DEFAULT,
    icon: 'help-2',
    title: 'Sidan uppdateras inte kontinuerligt.',
    message: [
      {
        type: svs.components.dialog.message.TEXT,
        text: 'För att se senaste informationen behöver du ladda om sidan.'
      }
    ],
    actions: [
      {
        title: 'Okej'
      }
    ]
  });
  svs.components.dialog.api.add(deadAlert);
});


 })(window);